<template>
  <div>
    <div>
      <AlertErrors :form="filters" />

      <b-form @submit.prevent="analyze()" inline>
        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Month & Year
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.year_month"
            type="month"
            :state="filters.errors.has('year_month') ? false : null"
            :required="filters.year_month != ''"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" size="sm" block>
          Analyse
        </b-button>
      </b-form>
    </div>

    <div class="mt-5">
      <span>
        Revenue & Expenses for
        {{ filters.year_month | moment("MMMM, YYYY") }}
      </span>

      <b-row>
        <b-col md="6">
          <b-table-simple bordered responsive>
            <b-tbody>
              <tr>
                <th width="50%">Total Revenue</th>
                <td>{{ totalRevenue.toLocaleString("en-US") }}</td>
              </tr>
              <tr>
                <th>Total Expenses</th>
                <td>{{ totalExpenses.toLocaleString("en-US") }}</td>
              </tr>
              <!-- TODO: Management to conclude formula later -->
              <!-- <tr>
                <th>
                  GeroCare Profit<br />
                  <i class="small">30&percnt; of Revenue</i>
                </th>
                <td>{{ gerocareProfit.toLocaleString("en-US") }}</td>
              </tr> -->
              <tr>
                <th>
                  Actual Profit<br />
                  <i class="small">Total Revenue - Total Expenses</i>
                </th>
                <td>{{ actualProfit.toLocaleString("en-US") }}</td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-table-simple bordered hover caption-top responsive>
        <!-- REVENUE -->
        <b-thead>
          <!-- WEEK DATES -->
          <b-tr class="table-primary">
            <b-th width="10">Revenue</b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number">
              {{ week.from | moment("MMMM DD") }} -
              {{ week.to | moment("DD, YYYY") }}
            </b-th>
            <b-th>Total</b-th>
          </b-tr>
        </b-thead>

        <!-- MEDICAL REQUEST REVENUE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Medical Request</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in medicalRequestInvoiceItems"
            :key="index"
          >
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.name | startCase }}
              <span v-if="item.is_logistics" class="small">
                <br />
                logistics
              </span>
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{
                excludePairedRevenue(item, week.week_number).toLocaleString(
                  "en-US"
                )
              }}
            </b-td>
             <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

        <!-- SUBSCRIPTION REVENUE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Subscription Revenue</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in appRevenue"
            :key="index"
          >
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.type | startCase }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
                {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

        <!-- ONDEMAND SUBSCRIPTION REVENUE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Ondemand Subscription Revenue</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, index) in appOndemandRevenue"
            :key="index"
          >
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.name | startCase }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

        <!-- QUICK INVOICE REVENUE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Quick Invoice</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th></b-th>
            <b-th></b-th>
            <b-th>Others</b-th>
            <!-- <b-td></b-td> -->
            <b-td v-for="week in weeks" :key="week.week_number">
              {{
                quickInvoiceRevenue[`week_${week.week_number}`].toLocaleString(
                  "en-US"
                )
              }} 
            </b-td>
            <b-td> {{ sumQuickInvoice(quickInvoiceRevenue) }}</b-td>
          </b-tr>
        </b-tbody>
        <!-- EXPENSES -->
        <b-thead>
          <b-tr class="table-danger">
            <b-th>Expenses</b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number">
              {{ week.from | moment("MMMM DD") }} -
              {{ week.to | moment("DD, YYYY") }}
            </b-th>
            <b-th>Total</b-th>
          </b-tr>
        </b-thead>

        <!-- PAYOUT REQUEST EXPENSE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Payout Requests</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in payoutRequestInvoiceItems" :key="index">
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.name }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

        <!-- VENDOR PAYMENT EXPENSE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Vendor Payment</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in vendorPaymentTypes" :key="index">
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.type | startCase }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

         <!-- BROKER PAYMENT EXPENSE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Broker Payment</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in appBrokerExpenses" :key="index">
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.type | startCase }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>

         <!-- DOCTOR PAYMENT EXPENSE -->
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Doctor Payment</b-th>
            <b-th></b-th>
            <b-th v-for="week in weeks" :key="week.week_number"></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in appDoctorExpenses" :key="index">
            <b-td></b-td>
            <b-td></b-td>
            <b-th>
              {{ item.type | startCase }}
            </b-th>
            <b-td v-for="week in weeks" :key="week.week_number">
              {{ item[`week_${week.week_number}`].toLocaleString("en-US") }}
            </b-td>
            <b-td>{{ getSum(item) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { gerohttp } from '@/utils/gerocarehttp';
import { geroendpoints } from '@/utils/endpoints';

export default {
  name: "RevenueExpenses",
  data() {
    return {
      loading: true,
      filters: new this.$Form({
        year_month: this.$moment().format("YYYY-MM"),
      }),
      crmAnalytics: null,
      appAnalytics: null,
      crmRevExpLoading: true,
    };
  },
  computed: {
    weeks() {
      return this.appAnalytics?.weeks;
    },
    medicalRequestInvoiceItems() {
      return this.crmAnalytics?.medical_request_revenue.filter(
        (item) => !item.name.includes("_paired")
      );
    },
    quickInvoiceRevenue() {
      return this.crmAnalytics?.quick_invoice_revenue[0];
    },
    payoutRequestInvoiceItems() {
      return this.crmAnalytics?.payout_request_expenses;
    },
    vendorPaymentTypes() {
      return this.crmAnalytics?.vendor_payment_expenses;
    },
    appRevenue(){
      return this.appAnalytics?.revenue;
    },
    appDoctorExpenses(){
      return this.appAnalytics?.doctor_expenses;
    },
    appBrokerExpenses(){
      return this.appAnalytics?.broker_expenses;
    },
    appOndemandRevenue(){
      return this.appAnalytics?.ondemand_revenue;
    },
    totalRevenue() {
      let total = 0;

      this.weeks?.forEach((week) => {
        // Medical request
        this.medicalRequestInvoiceItems?.forEach((item) => {
          total += this.excludePairedRevenue(item, week.week_number);
        });

        // Quick invoice
        total += this.quickInvoiceRevenue[`week_${week.week_number}`];

        //subscription revenue
        this.appRevenue?.forEach((revenue) => {
          total += revenue[`week_${week.week_number}`]
        })

        //ondemand revenue
        this.appOndemandRevenue?.forEach((ondemandRevenue) => {
          total += parseInt(ondemandRevenue[`week_${week.week_number}`])
        })

      });

      return total;
    },
    totalExpenses() {
      let total = 0;

      this.weeks?.forEach((week) => {
        this.payoutRequestInvoiceItems?.forEach((item) => {
          total += item[`week_${week.week_number}`];
        });

        this.vendorPaymentTypes?.forEach((item) => {
          total += item[`week_${week.week_number}`];
        });

        //Doctor expenses
        this.appDoctorExpenses?.forEach((expense) => {
          total += expense[`week_${week.week_number}`]
        });

        //Broker expense
        this.appBrokerExpenses?.forEach((brokerExpense) => {
          total += brokerExpense[`week_${week.week_number}`]
        });

      });

      return total;
    },
    gerocareProfit() {
      return (30 / 100) * this.totalRevenue;
    },
    actualProfit() {
      return this.totalRevenue - this.totalExpenses;
    },
  },
  methods: {
    fetchAnalytics() {
      this.crmRevExpLoading = true;

      this.filters
        .get(endpoints.GET_REVENUE_EXPENSES_ANALYTICS)
        .then((response) => {
          this.crmAnalytics = response.data;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.crmRevExpLoading = false;
        });
    },

    fetchAppAnalytics() {
      this.crmRevExpLoading = true;
      let query = `?year_month=`+this.filters.year_month
      gerohttp
        .get(geroendpoints.FETCH_APP_REVENUE_EXPENSES+query)
        .then((response) => {
          this.appAnalytics = response.data
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.crmRevExpLoading = false
        });
    },

    excludePairedRevenue(item, weekNumber) {
      let pair = this.crmAnalytics.medical_request_revenue.filter(
        (inItem) => inItem.name === item.name + "_paired"
      )[0];
      let pairAmount = 0;

      if (pair) {
        pairAmount = pair[`week_${weekNumber}`];
      }

      return item[`week_${weekNumber}`] - pairAmount;
    },

     getSum(data) {
      let values;
      if (data.hasOwnProperty('is_logistics')) {
        delete data.is_logistics;
      }

      if (data.hasOwnProperty('name') || data.hasOwnProperty('type')) {
        values = Object.values(data).slice(1);
      } else {
        values = Object.values(data);
      }

      const sum = values
        .map(value => parseInt(value))
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      return sum;
    },

    sumQuickInvoice(data) {
      if (data) {
        const values = Object.values(data);
        const sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        return sum;
      }
    },

    analyze(){
      this.fetchAnalytics();
      this.fetchAppAnalytics();
    },
  },
  created() {
    this.fetchAnalytics();
    this.fetchAppAnalytics();
  },
};
</script>

<style lang="scss" scoped>
.table {
  tr {
    th {
      color: black;
    }
  }
}
</style>
